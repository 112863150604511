<template>
  <div class="steps-table">
    <div v-if="steps.length"
         class="table-responsive">
      <table class="table">
        <thead>
        <tr>
          <th v-if="showDepartments">Dépt</th>
          <th class="step-number-th">#</th>
          <th>Desc</th>
          <th>Edit</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(step, index) in steps"
            :key="index">
          <td v-if="showDepartments">
            <div class="department department-small">
              {{ department(step) }}
            </div>
          </td>
          <td>
            <div class="step-number">
              <template v-if="step.properties.way === 0">
                <i class="icon-location"></i>&nbsp;<span>{{ step.properties.num_step }}</span>
              </template>
              <template v-else>
                <img class="return-icon"
                     src="../assets/images/icon-return-red.svg"
                     alt=""
                     title="retour">&nbsp;<span>{{ step.properties.num_step }}</span>
              </template>
            </div>
          </td>
          <td>{{ stepAddress(step) }}</td>
          <td>
            <div class="step-actions">
              <div @click="editStep(step)"
                   title="Modifier le point">
                <i class="icon-btn-edit"></i>
              </div>
              <div @click="deleteStepAndReIndex(step)"
                   title="Supprimer le point">
                <i class="icon-trash"></i>
              </div>
              <div @click="addStep(step)"
                   title="Ajouter un point juste après">
                <i class="icon-add"></i>
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div v-else>
      <p>Aucun tronçon pour le moment...</p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  props: {
    steps: {
      type: Array,
      required: true
    },
    showDepartments: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    ...mapState({
      authorization: state => state.authorization.authorization
    })
  },
  methods: {
    ...mapActions({
      removeStep: 'authorization/removeStep',
      saveSteps: 'authorization/saveSteps'
    }),
    department (step) {
      return step.properties.num_department || '-'
    },
    stepAddress (step) {
      if (!step.properties.address) {
        return '--'
      }
      return step.properties.address.split(', France')[0]
    },
    stepComment (step) {
      return step.properties.comment && step.properties.comment.length
        ? 'oui'
        : 'non'
    },
    stepPage (step) {
      return step.properties.page_number === null ? '-' : step.properties.page_number
    },
    editStep (step) {
      this.$router.push({
        name: 'authorizations.steps.edit',
        params: {
          id: step.properties.id_authorization,
          num_step: step.properties.num_step
        }
      })
    },
    deleteStepAndReIndex (step) {
      if (window.confirm('Êtes-vous sûr de vouloir supprimer ce point ? Cette action est irreversible.')) {
        this.removeStep(step).then(() => {
          this.saveSteps()
        })
      }
    },
    addStep (step) {
      this.$router.push({
        name: 'authorizations.steps.create-next',
        params: {
          id: this.$route.params.id,
          num_step: step.properties.num_step
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .table {
    margin-top: 10px;
    font-size: 12px;
    width: 100%;

    @media (max-width: $breakpoint-md) {
      font-size: 11px;
    }

    thead {
      border-bottom: none;
    }

    th,
    td {
      padding-left: 5px;
      padding-right: 5px;

      @media (max-width: $breakpoint-md) {
        padding-left: 3px;
        padding-right: 3px;
      }
    }

    .step-number-th {
      padding-right: 27px;

      @media (max-width: $breakpoint-md) {
        padding-right: 25px;
      }
    }

    th {
      font-weight: normal;
      color: $black;
      border-bottom: 2px solid #a2a2a2;
    }

    td {
      border-top: 1px solid #a2a2a2;
    }

    tbody tr:last-child td {
      border-bottom: 1px solid #a2a2a2;
    }

    tr th:first-child,
    tr td:first-child {
      padding-left: 2px;
    }

    tr th:last-child,
    tr td:last-child {
      padding-right: 0;
    }
  }

  .step-number {
    i {
      color: $red;
    }

    span {
      vertical-align: 1px;
      margin-left: -2px;
    }
  }

  .step-actions {
    display: flex;
    justify-content: left;
    align-items: center;
    color: $red;
    font-size: 20px;

    @media (max-width: $breakpoint-md) {
      font-size: 16px;
    }

    div {
      margin-right: 3px;

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  .return-icon {
    height: 13px;
    width: auto;
    vertical-align: 0;

    @media (max-width: $breakpoint-md) {
      height: 12px;
    }
  }
</style>
