<template>
  <SplitPage v-if="authorization">
    <template #header>
      <AuthorizationBreadcrumb :active-step="2"/>
    </template>

    <template #left-top>
      <StepsTabs/>
      <StepsSwitch @by-department="toggleSwitch"
                   :by-department="byDepartment"/>
    </template>

    <template #left-content>
      <div class="mt-5 mb-4">
        <StepsList v-if="!byDepartment"
                   :authorization="authorization"
                   :steps="steps"/>
        <StepsListByDepartments v-else
                                :authorization="authorization"
                                :steps="steps"/>
      </div>
      <button v-if="steps.length"
              @click="visualize"
              class="btn btn-block btn-secondary">
        Visualiser
      </button>
    </template>

    <template #right-content>
      <AuthorizationMap :center="center"/>
    </template>
  </SplitPage>
</template>

<script>
import SplitPage from '@/components/SplitPage'
import AuthorizationBreadcrumb from '@/components/AuthorizationBreadcrumb'
import StepsTabs from '@/components/StepsTabs'
import StepsSwitch from '@/components/StepsSwitch'
import StepsList from '@/components/StepsList'
import StepsListByDepartments from '@/components/StepsListByDepartments'
import AuthorizationMap from '@/components/AuthorizationMap'
import { mapState, mapActions } from 'vuex'
import geojsonExtent from '@mapbox/geojson-extent'
import geojsonMerge from '@mapbox/geojson-merge'

export default {
  name: 'AuthorizationsStepsIndexPage',
  components: {
    SplitPage,
    AuthorizationBreadcrumb,
    StepsTabs,
    StepsSwitch,
    StepsList,
    StepsListByDepartments,
    AuthorizationMap
  },
  data () {
    return {
      byDepartment: false
    }
  },
  computed: {
    ...mapState({
      authorization: state => state.authorization.authorization,
      steps: state => state.authorization.steps
    }),
    center () {
      const steps = this.steps
      steps.forEach((item, index, all) => {
        all[index] = geojsonExtent.bboxify(item)
      })
      return geojsonExtent.bboxify(geojsonMerge.merge(steps)).bbox
    }
  },
  methods: {
    ...mapActions({
      getAuthorization: 'authorization/getAuthorization',
      getSteps: 'authorization/getSteps',
      resetState: 'authorization/resetState'
    }),
    toggleSwitch (value) {
      this.byDepartment = value
    },
    visualize () {
      this.$router.push({
        name: 'authorizations.steps.show',
        params: {
          id: this.$route.params.id,
          num_step: this.steps[0].properties.num_step
        }
      })
    }
  },
  mounted () {
    this
      .getAuthorization(this.$route.params.id)
      .then(() => {
        this.getSteps(this.$route.params.id).then(() => {
          if (!this.steps.length) {
            this.$router.push({
              name: 'authorizations.steps.create',
              params: {
                id: this.$route.params.id
              }
            })
          }
        })
      })
      .catch(() => this.$router.push({ name: '404' }))
  },
  beforeDestroy () {
    this.resetState()
  }
}
</script>

<style scoped lang="scss">

</style>
