<template>
  <div class="steps-list">
    <div v-if="steps.length"
         class="table-responsive">
      <StepsTable :steps="steps"/>
    </div>
    <div v-else>
      <p>Aucun tronçon pour le moment...</p>
    </div>
  </div>
</template>

<script>
import StepsTable from '@/components/StepsTable'
import { mapState } from 'vuex'

export default {
  components: {
    StepsTable
  },
  computed: {
    ...mapState({
      steps: state => state.authorization.steps
    })
  }
}
</script>
