<template>
  <div class="steps-switch">
    <div :class="allClasses">tous les tronçons</div>
    <div class="custom-control custom-switch">
      <input type="checkbox"
             class="custom-control-input"
             id="stepsSwitch">
      <label @click="toggle"
             class="custom-control-label"
             for="stepsSwitch">
      </label>
    </div>
    <div :class="byDepartmentClasses">par département</div>
  </div>
</template>

<script>
export default {
  props: {
    byDepartment: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    allClasses () {
      return {
        'steps-switch-item': true,
        active: !this.byDepartment
      }
    },
    byDepartmentClasses () {
      return {
        'steps-switch-item': true,
        active: this.byDepartment
      }
    }
  },
  methods: {
    toggle (event) {
      this.$emit('by-department', !this.byDepartment)
    }
  }
}
</script>

<style scoped lang="scss">
  .steps-switch {
    padding: 10px 3px;
    background: rgba(120, 141, 163, .05);
    border-bottom: 1px solid #ddd;
    box-shadow: 0 2px 7px 0 rgba($black, .13);
    background: blue;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(120, 141, 163, 0.05);

    @media (max-width: $breakpoint-md) {
      padding: 5px 3px;
    }
  }

  .custom-switch {
    margin-left: 10px;
    margin-right: 10px;

    @media (max-width: $breakpoint-md) {
      margin-left: 8px;
      margin-right: 8px;
    }
  }

  .custom-control-label:hover {
    cursor: pointer;
  }

  .custom-switch .custom-control-label:before {
    background: $red;
  }

  .custom-switch .custom-control-label:after {
    background: $white;
  }

  .steps-switch-item {
    font-size: 13px;

    @media (max-width: $breakpoint-md) {
      font-size: 12px;
      width: 33%;
    }

    &.active {
      font-weight: $font-weight-bold;
      color: $red;
    }
  }
</style>
