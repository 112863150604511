<template>
  <div class="steps-list-by-departments">
    <div class="accordion" id="accordion">
      <div v-for="(departmentContent, departmentNumber, index) in departments"
           :key="index"
           class="card">
        <div class="card-header"
             :id="`heading${index}`">
          <div class="accordion-trigger"
               type="button"
               data-toggle="collapse"
               :data-target="`#collapse${index}`"
               aria-expanded="false"
               :aria-controls="`collapse${index}`">
            {{ departmentNumber }} - {{ departmentContent.length }}
            {{ departmentContent.length === 1 ? 'tronçon' : 'tronçons' }}
          </div>
        </div>

        <div :id="`collapse${index}`"
             class="collapse"
             :aria-labelledby="`heading1${index}`"
             data-parent="#accordion">
          <div class="card-body">
            <StepsTable :steps="departmentContent"
                        :show-departments="false"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StepsTable from '@/components/StepsTable'

export default {
  components: {
    StepsTable
  },
  props: {
    authorization: {
      type: Object,
      required: true
    },
    steps: {
      type: Array,
      required: true
    }
  },
  computed: {
    departments () {
      const departments = {}
      this.steps.forEach(step => {
        if (!departments[step.properties.num_department]) {
          departments[step.properties.num_department] = []
        }
        departments[step.properties.num_department].push(step)
      })
      return departments
    }
  }
}
</script>

<style scoped lang="scss">
  .step-list-by-departments {
    //
  }

  .card {
    margin-top: 70px;
    border: 1px solid $red;
    border-radius: 0;
  }

  .card-header {
    padding: 0;
    background: $white;
  }

  .accordion-trigger {
    padding: 15px 20px;
    font-size: 15px;
    line-height: 1;
  }

  .card-body {
    padding: 15px 10px 0 10px;

    @media (max-width: $breakpoint-md) {
      padding: 12px 8px 0 8px;
    }
  }

  .accordion-trigger {
    @media (max-width: $breakpoint-md) {
      font-size: 13px;
      padding: 12px 15px;
    }
  }
</style>
